import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h2`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 44px;
  margin-bottom: 24px;
`
