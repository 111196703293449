import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

import BannerTopo from "components/bannerTopo"
import InfosPage from "components/InfosPage"
import Programs from "components/programs"
import Testimonials from "components/testimonials"

import { getBucketUrl, getDepartments } from "../../../service"
import DepartmentsList from "../../departmentsList"
import { jobsBoardResource } from "../../../resources/jobsBoard"

import Tabs from "../../tabs"
import Tab from "../../tabs/components/tab"

const Team = props => {
  const [team, setTeam] = useState({})
  const [api, setApi] = useState({})
  const [jobBoard, setJobBoard] = useState([])

  const init = async () => {
    setApi(props.apiData)

    let checkTeam = null

    let jobs = []
    if (props.team === "comercial") {
      const promises = jobsBoardResource[props.team].tabs.map(async tab => {
        if (tab.path) {
          const { departments } = await getDepartments(String(tab.path))
          return { name: tab.name, departments }
        }
      })
      jobs.push(await Promise.all(promises))
    } else {
      const { departments } = await getDepartments(
        jobsBoardResource[props.team].path
      )
      jobs.push({ name: props.team, departments })
    }
    setJobBoard(...jobs)

    if (
      props.apiData.collections.teams &&
      Array.isArray(props.apiData.collections.teams)
    ) {
      props.apiData.collections.teams.forEach(element => {
        if (element.slug === props.team) checkTeam = element
      })
    }

    if (!checkTeam) {
      navigate("/").catch()
    } else {
      setTeam(checkTeam)
    }
  }

  useEffect(() => {
    !jobsBoardResource.hasOwnProperty(props.team) ? navigate('/times') : init().then()
  }, [props.team])

  return (
    <>
      {Object.keys(team).length && (
        <>
          <Helmet>
            <title>{`${team.title} | Times | Jornada Stone`}</title>
          </Helmet>
          <InfosPage titlePage={team.title} text={team.subtitle} />
          <BannerTopo image={getBucketUrl(team.image.path)} />
          <div className="u-wrapper u-wrapper--mb">
            {jobBoard.length > 1 ? (
              <>
                <h2
                  style={{
                    paddingLeft: 14,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Escolha aqui um perfil de vaga:
                </h2>
                <Tabs>
                  {jobBoard.map(board => (
                    <Tab key={board.name} title={board.name}>
                      <DepartmentsList
                        departments={board.departments}
                        hiddenChallenge={true}
                      />
                    </Tab>
                  ))}
                </Tabs>
              </>
            ) : (
              <DepartmentsList departments={jobBoard.departments || []} />
            )}
          </div>
          {team.testimonials &&
            Array.isArray(team.testimonials) &&
            team.testimonials.length > 0 && (
              <Testimonials
                testimonials={team.testimonials.map(item => item.value)}
              />
            )}
          <Programs programs={api.collections.programs} />
        </>
      )}
    </>
  )
}

export default Team
