import styled from "styled-components"

export const PaginationContainer = styled.div`
  && {
    width: auto;
    .pagination {
      width: 100%;
      display: flex;
      list-style: none;
      outline: none;
      li > a {
        padding: 0.5rem 1rem;
        outline: none;
        cursor: pointer;

        svg {
          polygon {
            fill: #00a868;
          }
        }
      }
    }
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
      background-color: #00a868;
      color: #ffffff;
      outline: none;
    }
    .pagination > li > a,
    .pagination > li > span {
      color: #00a868;
      border-radius: 4px;
    }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border-radius: unset;
    }
  }
`
