import React from "react"
import * as Styles from "./styles"
import { FcNext, FcPrevious } from "react-icons/fc"
import ReactPaginate from "react-paginate"

const Pagination = ({
  maxCount = 0,
  currentPage = 0,
  onPageChange,
  ...props
}) => {
  return (
    <Styles.PaginationContainer>
      <ReactPaginate
        previousLabel={<FcPrevious />}
        nextLabel={<FcNext />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={maxCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage}
        {...props}
      />
    </Styles.PaginationContainer>
  )
}

export default Pagination
