import React, { useEffect, useState } from "react"

import Pagination from "../pagination"
import Select from "../select"
import CardLink from "./components/cardLink"
import * as Styles from "./styles"

const DepartmentsList = ({ departments, hiddenChallenge = false }) => {
  const [jobs, setJobs] = useState([])
  const [jobsList, setJobsList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [jobNameOption, setJobNameOption] = useState([])
  const [allCities, setAllCities] = useState([])
  const [citiesOption, setCitiesOption] = useState([])
  const [statesOption, setStatesOption] = useState([])

  const [jobSearch, setJobSearch] = useState({
    challenge: 0,
    state: "",
    city: "",
  })

  const splitJobsFromDepartments = (departments = [], tag = null, tagId) => {
    let jobs = []
    /* eslint-disable no-unused-expressions */
    departments?.forEach(department => {
      const { children = [], id, name } = department

      const currentJobs = getJobsFromDepartment(department).map(currentJob => {
        return {
          tag: name || tag,
          tagId: id || tagId,
          ...currentJob,
        }
      })

      jobs.push(...currentJobs)

      if (children?.length > 0) {
        const currentTag = tag || name
        const currentTagId = tagId || id
        jobs.push(
          ...splitJobsFromDepartments(children, currentTag, currentTagId)
        )
      }
    })

    return jobs
  }

  const getJobsFromDepartment = department => {
    const jobs = []
    const { jobs: departmentJobs = [] } = department
    departmentJobs?.forEach(departmentJob => {
      const urlReplace = departmentJob.absolute_url.replace("formulario/", "")
      const urlSplit = urlReplace.split("/")
      const localeJson = departmentJob.location.name.split(",")
      const state =
        departmentJob.metadata.find(element => element.name === "Estado:")
          .value ||
        localeJson[1] ||
        "remoto"
      const city = localeJson[0] || departmentJob.location.name

      jobs.push({
        id: departmentJob.id,
        internalId: departmentJob.internal_job_id,
        title: departmentJob.title,
        location: departmentJob.location.name,
        city: city.toLowerCase().trim(),
        state: state.toLowerCase().trim(),
        linkKey: urlSplit[3].split("?", 1)[0],
      })
    })
    return jobs
  }

  const changePagination = ({ selected }) => {
    setCurrentPage(selected)
  }

  const handleJobNameSelect = data => {
    if (!data.value || data.value === 0) {
      setJobSearch({ ...jobSearch, challenge: 0 })
      return
    }
    setJobSearch({ ...jobSearch, challenge: data.value || 0 })
  }

  const handleStateSelect = data => {
    if (!data.value || data.value === 0) {
      setJobSearch({ ...jobSearch, state: "", city: "", challenge: "" })
      setCitiesOption([])
      return
    }
    setJobSearch({ ...jobSearch, state: data.value, city: "", challenge: "" })

    setCitiesOption(
      allCities.find(({ name }) => data.value === name)?.cities || []
    )
  }

  const handleCitySelect = data => {
    if (!data.value || data.value === 0) {
      setJobSearch({ ...jobSearch, city: "", challenge: "" })
      return
    }

    setJobSearch({ ...jobSearch, city: data.value || "", challenge: "" })
  }
  const titleize = word => {
    const ignore = ["de", "da", "das", "do", "dos"]
    const newWord = []
    const splited = word.split(" ")

    splited.forEach(word => {
      if (ignore.indexOf(word) === -1) {
        const firstLetter = word[0].toUpperCase()
        const restLetters = word.slice(1).toLowerCase()
        newWord.push(firstLetter + restLetters)
      } else {
        newWord.push(word)
      }
    })

    return newWord.join(" ")
  }

  useEffect(() => {
    let jobsFiltered = jobsList

    jobSearch.state
      ? (jobsFiltered = jobsList.filter(
          job => job.state.toLowerCase().trim() === jobSearch.state
        ))
      : ""
    jobSearch.city
      ? (jobsFiltered = jobsList.filter(
          job => job.city.toLowerCase().trim() === jobSearch.city
        ))
      : ""
    jobSearch.challenge > 0
      ? (jobsFiltered = jobsList.filter(job => job.id === jobSearch.challenge))
      : ""

    jobSearch.challenge < 1
      ? setJobNameOption(
          jobsFiltered.map(job => ({ value: job.id, label: job.title })) || []
        )
      : ""

    setJobs(paginator(jobsFiltered || [], 9))
  }, [jobSearch])

  useEffect(() => {
    const arrayJobs = splitJobsFromDepartments(departments || [])
    setJobs(paginator(arrayJobs || [], 9))
    setJobsList(arrayJobs)
  }, [departments])

  useEffect(() => {
    if (jobs.length > 0) {
      setJobNameOption(
        jobsList.map(job => ({ value: job.id, label: job.title })) || []
      )
      setStatesOption(
        jobsList
          .filter(
            (Job, index) =>
              jobsList.findIndex(data => data.state === Job.state) === index
          )
          .map(job => ({
            value: job.state,
            label: titleize(job.state),
          }))
          .sort((a, b) => {
            const arrA = a.label
            const arrB = b.label
            if (arrA > arrB) {
              return 1
            }
            if (arrA < arrB) {
              return -1
            }
            return 0
          }) || []
      )
    }
  }, [jobsList])

  useEffect(() => {
    setAllCities(
      statesOption.map(state => {
        const states = jobsList.filter(job => job.state === state.value)
        const cities = states
          .filter(
            ({ city }, index) =>
              states.findIndex(data => data.city === city) === index
          )
          .map(job => ({
            value: job.city,
            label: titleize(job.city),
          }))
          .sort((a, b) => {
            const arrA = a.label
            const arrB = b.label
            if (arrA > arrB) {
              return 1
            }
            if (arrA < arrB) {
              return -1
            }
            return 0
          })

        return { name: state.value, cities }
      })
    )
  }, [statesOption])

  useEffect(() => {
    changePagination({ selected: 0 })
  }, [jobs])

  const paginator = (jobs, max) => {
    if (jobs.length > 0) {
      return jobs.reduce((accumulator, item, index) => {
        const group = Math.floor(index / max)
        accumulator[group] = [...(accumulator[group] || []), item]
        return accumulator
      }, [])
    }
    return []
  }

  return (
    <Styles.Container>
      <Styles.SelectContainer>
        <Select
          label="Estados"
          value={statesOption.filter(
            select => select.value === jobSearch.state
          )}
          options={[
            { value: 0, label: "Selecione uma estado" },
            ...statesOption,
          ]}
          isDisabled={statesOption.length === 0}
          Styles={{ maxWidth: 378 }}
          placeholder="Selecione uma estado"
          onChange={handleStateSelect}
        />
        <Select
          label="Cidades"
          value={citiesOption.filter(select => select.value === jobSearch.city)}
          options={[
            { value: 0, label: "Selecione uma cidade" },
            ...citiesOption,
          ]}
          isDisabled={citiesOption.length === 0}
          Styles={{ maxWidth: 378 }}
          placeholder="Selecione uma cidade"
          onChange={handleCitySelect}
        />
        {!hiddenChallenge && (
          <Select
            label="Busque o seu desafio"
            value={jobNameOption.filter(
              select => select.value === jobSearch.challenge
            )}
            options={[
              { value: 0, label: "Selecione um desafio" },
              ...jobNameOption,
            ]}
            isDisabled={jobNameOption.length === 0}
            Styles={{ maxWidth: 378 }}
            placeholder="Selecione um desafio"
            onChange={handleJobNameSelect}
          />
        )}
      </Styles.SelectContainer>
      <Styles.CardsContainer>
        {jobs?.length > 0 ? (
          jobs[currentPage].map(job => {
            return (
              <CardLink
                key={job.id}
                title={job.title}
                tag={job.tag}
                link={`../${job.linkKey}/${job.id}`}
              />
            )
          })
        ) : (
          <Styles.NotCardsContainer>
            Sem vagas disponíveis para este departamento e localização no
            momento!
          </Styles.NotCardsContainer>
        )}
        <Styles.PaginationContainer>
          {jobs.length > 1 && (
            <Pagination
              currentPage={0}
              maxCount={jobs.length}
              onPageChange={changePagination}
            />
          )}
        </Styles.PaginationContainer>
      </Styles.CardsContainer>
    </Styles.Container>
  )
}

export default DepartmentsList
