import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  max-width: 378px;
  min-height: 130px;

  padding: 16px;

  background: #f3f7fc;
  border-radius: 4px;

  cursor: pointer;
  transition: background 0.5s, color 0.2s;
  color: #0d111b;

  .subtitle {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .area {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
  }

  &:hover {
    background: #01ab63;
    color: #f3f7fc;
  }

  @media (max-width: 1024px) {
    flex-basis: calc(50% - 16px);
    max-width: none;
    min-height: none;
  }

  @media (max-width: 640px) {
    flex-basis: 100%;
    max-width: none;
    min-height: none;
  }
`
