import React from "react"
import * as Styles from "./styles"

const CardLink = ({ title, link = "/", tag = "" }) => {
  return (
    <Styles.Container to={link}>
      <div className="subtitle"># {tag}</div>
      <div className="area">{title}</div>
    </Styles.Container>
  )
}

export default CardLink
