import styled, { css } from "styled-components"

export const ContentLi = styled.li`
  display: flex;
  flex: 1 1 auto;

  button {
    width: 100%;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #01ab63;
    }

    ${({ active }) =>
      active &&
      css`
        color: #fff;
        background-color: #01ab63;
      `}
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`
