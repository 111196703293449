import * as React from "react"
import * as S from "./styles"

type Props = {
  title: string
}

const Tab: React.FC<Props> = ({ title, children }) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      {children}
    </S.Container>
  )
}

export default Tab
