import { useCallback } from "react"
import * as React from "react"
import * as S from "./styles"

type Props = {
  title: string
  index: number
  actived: number
  setSelectedTab: (index: number) => void
}

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  actived = 0,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <S.ContentLi active={actived === index}>
      <button onClick={onClick}>{title}</button>
    </S.ContentLi>
  )
}

export default TabTitle
