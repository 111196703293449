import * as React from "react"
import { ReactElement, useState } from "react"
import TabTitle from "./components/TabTitle"

import * as S from "./styles.js"

type TabsProps = {
  children: ReactElement[]
}

const Tabs = ({ children }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <S.Container>
      <S.ContentUl>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            index={index}
            title={item.props.title}
            actived={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </S.ContentUl>
      {children[selectedTab]}
    </S.Container>
  )
}

export default Tabs
