import React, { useState } from "react"
import Team from "components/pages/team"
import Teams from "components/pages/teams"
import Layout from "components/layout/layout"
import { Router } from "@reach/router"

const Times = () => {
  const [api, setApi] = useState({})

  const init = apiData => {
    setApi(apiData)
  }

  return (
    <Layout
      loadedCallback={init}
      pageTitle="Times"
      pageDescription="Conheça os times Stone Co. e candidate-se"
    >
      {api && api.constructor === Object && Object.keys(api).length !== 0 && (
        <Router basepath="times">
          <Teams apiData={api} path="/" />
          <Team apiData={api} path="/:team" />
        </Router>
      )}
    </Layout>
  )
}

export default Times
