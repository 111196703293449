import React, { useEffect, useState } from "react"
import BannerTopo from "components/bannerTopo"
import InfosPage from "components/InfosPage"
import Cards from "components/cards"
import { getSiteUrl, getBucketUrl } from "../../../service"

const Teams = props => {
  const [api, setApi] = useState({})

  useEffect(() => {
    setApi(props.apiData)
  }, [props.apiData])

  function getTeams() {
    let teams = []

    api.collections.teams.forEach(element => {
      teams.push(element)
    })

    return teams
  }

  return (
    <div>
      <>
        {api && api.constructor === Object && Object.keys(api).length !== 0 && (
          <>
            <InfosPage titlePage="Times" text={api.singletons.teams.text} />
            <BannerTopo
              image={getBucketUrl(api.singletons.teams.image.path)}
              title="times"
            />
            <div className="u-wrapper u-wrapper--mb">
              <div className="c-cards-wrapper c-cards-wrapper--spaced">
                <Cards
                  hasLink={true}
                  hasIcon={true}
                  path="/times/"
                  modifier="team"
                  visible={6}
                  cards={getTeams()}
                />
              </div>
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default Teams
