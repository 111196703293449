import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
  gap: 16px;
  > div {
  }

  @media (max-width: 1024px) {
    > div {
      flex: 1 1 auto;
      flex-basis: calc(50% - 16px);
      max-width: none !important;
    }
  }

  @media (max-width: 640px) {
    > div {
      flex-basis: 100%;
      max-width: none !important;
    }
  }
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
`
export const NotCardsContainer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #00a868;
  margin-top: 16px;
`
